import React, { forwardRef, Ref, useState } from 'react';
import { buildClassName } from 'utils/build-class-name';
import TextInput from '@zoocasa/node-kit/components/controls/text-input';
import EyeOpenIcon from 'components/icon/eye-open-icon';
import EyeClosedIcon from 'components/icon/eye-closed-icon';
import styles from './style.module.scss';

import type { TextInputProps } from '@zoocasa/node-kit/components/controls/text-input';
import type { FieldError, FieldErrors, Merge } from 'react-hook-form';

type Props = TextInputProps & {
  label?: string;
  isHidden?: boolean;
  errors?: { message: string } | string | FieldError | Merge<FieldError, FieldErrors<any>>;
  autocomplete?: string;
  isCurrency?: boolean;
  containerClassName?: string;
  testId?: string;
  labelInline?: boolean;
};

export default forwardRef((
  { label, isHidden, errors, containerClassName, className, autocomplete, type, isCurrency, testId, labelInline, ...inputProps }
  : Props, ref?: Ref<HTMLInputElement>) => {
  const componentClassName = buildClassName(styles.component, containerClassName, isHidden && styles.hidden, labelInline && styles['inline-label']);
  const inputClassName = buildClassName(className, errors && styles.error);
  const [isPasswordSeen, setIsPasswordSeen] = useState(false);
  const isPassword = type === 'password';
  const inputType = isPassword && isPasswordSeen ? 'text' : type;

  return (
    <div className={componentClassName}>
      {label && <label className={buildClassName(labelInline && styles.inline)}>{label}</label>}
      <div className={buildClassName(styles['input-container'], isCurrency && styles['currency-icon'])}>
        <TextInput
          {...inputProps}
          type={inputType}
          className={inputClassName}
          ref={ref}
          autoComplete={autocomplete}
          data-testid={testId} />
        {isPassword && (
          <>
            {isPasswordSeen ?
              <EyeClosedIcon className={styles.eye} onClick={() => setIsPasswordSeen(!isPasswordSeen)} /> :
              <EyeOpenIcon className={styles.eye} onClick={() => setIsPasswordSeen(!isPasswordSeen)} />
            }
          </>
        )}
      </div>
      {typeof errors === 'object' &&
        <span className={buildClassName(styles['error-message'], label && !labelInline && styles['error-message-with-label'])}>
          {errors.message?.toString()}
        </span>
      }
    </div>
  );
});
