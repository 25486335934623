import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useSavedListingContext } from 'contexts';
import Button from 'components/control/button';
import Image from 'next/image';
import Checkbox from 'components/control/checkbox';
import { HIDE_SAVE_HOME_COOKIE } from 'constants/cookies';
import styles from './style.module.scss';

import type { ListingInfoType } from 'components/connection-request';

interface Props {
  listing: ListingInfoType;
  setHasRespondedToFavourites: (hasResponded: boolean) => void;
}

export default function SaveHome({ listing, setHasRespondedToFavourites }: Props) {
  const { addSavedListing } = useSavedListingContext();
  const { id: listingId, streetAddress } = listing;
  const [hideSaveHome, setHideSaveHome] = useState(!!Cookies.get(HIDE_SAVE_HOME_COOKIE));

  const saveHome = async () => {
    addSavedListing(listingId);
    setHasRespondedToFavourites(true);
  };

  useEffect(() => {
    hideSaveHome ? Cookies.set(HIDE_SAVE_HOME_COOKIE, 'true') : Cookies.remove(HIDE_SAVE_HOME_COOKIE);
  }, [hideSaveHome]);

  return (
    <div className={styles.component}>
      <div className={styles.header}>
        <Image src={'/next/assets/images/save-home.png'} width="41" height="33" alt="Save home" />
        <h3>Save this property</h3>
      </div>
      <p>Keep up with the latest update on {streetAddress}! Would you like to add this property to your favourites for quick and easy access?</p>
      <Checkbox 
        label="Don't show this again" 
        value={hideSaveHome}
        onValueChange={setHideSaveHome}
        className={styles['checkbox-container']} />
      <div className={styles.buttons}>
        <Button label="No" onClick={() => setHasRespondedToFavourites(true)} theme="secondary" />
        <Button label="Yes" onClick={saveHome} theme="primary" />
      </div>
    </div>
  );
}
