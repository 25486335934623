import React, { ReactElement, useState, useEffect } from 'react';
import { camelize } from '@zoocasa/node-kit/strings/camelize';
import CheckboxInput from '@zoocasa/node-kit/components/controls/checkbox-input';
import { buildClassName } from 'utils/build-class-name';
import CheckmarkIcon from 'components/icon/checkmark-icon';
import { v4 as uuid } from 'uuid';
import styles from './style.module.scss';

import type { CheckboxInputProps } from '@zoocasa/node-kit/components/controls/checkbox-input';

type Props = CheckboxInputProps & {
  label: string | ReactElement;
  testId?: string;
  hasError?: boolean;
};

export default function Checkbox({ label, testId, hasError, name, value, className, onValueChange, ...inputProps }: Props) {
  const [id, setId] = useState('');
  const testId_replace = testId?.replace(/\\|\//g, '');

  useEffect(() => {
    setId('input-' + camelize(name || label.toString()) + '-' + uuid());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={buildClassName(styles.component, value && styles.active, hasError && styles.error, className)}>
      <CheckmarkIcon className={styles.checkmark} onClick={() => onValueChange && onValueChange(!value!)} />
      <label htmlFor={id} data-testid={testId_replace}>{label}</label>
      <CheckboxInput id={id} onValueChange={onValueChange} {...inputProps} name={name} value={value} data-testid={testId_replace + 'Input'} />
    </div>
  );
}
